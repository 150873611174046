:root {
  --black: #051014;
  --white: #DDFFF7;
  --purple: #873795;
}

@font-face {
  font-family: 'Bleeding Cowboys';
  src: local('Bleeding Cowboys'), url(./fonts/Bleeding_Cowboys.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Coco Gothic';
  src: local('Coco Gothic'), url(./fonts/Coco-Gothic-light.ttf) format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Coco Gothic';
  src: local('Coco Gothic'), url(./fonts/Coco-Gothic-regular.ttf) format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Coco Gothic';
  src: local('Coco Gothic'), url(./fonts/Coco-Gothic-bold.ttf) format('truetype');
  font-weight: 700;
  font-style: normal;
}

html {
  background-color: var(--black);
  color: var(--white);
  font-family: 'Coco Gothic';
  font-weight: 200;
}

body {
  margin: 0;
}

a {
  color: var(--purple);
  font-weight: 700;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Bleeding Cowboys';
  color: var(--purple);
  margin: 0;
  text-align: center;
}