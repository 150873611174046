.page-wrap {
  display: flex;
  flex-direction: column;

  padding: 16px 24px 24px;

  max-width: 400px;
  margin: 0 auto;
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.text-center {
  text-align: center;
}

.border-radius {
  border-radius: 24px;
}

.horse-logo {
  max-width: 100px;
  max-height: 100px;
  width: auto;
  height: auto;

  padding-right: 24px;
}

.star-stable-logo {
  max-width: 200px;
  max-height: 200px;
  width: auto;
  height: auto;

  align-self: center;
}

.death-icon {
  max-width: 48px;
  max-height: 48px;

  padding-right: 24px;
}

.not-found-wrap {
  padding-top: 60px;
  padding-left: 32px;
  padding-right: 32px;

  max-width: 400px;
  margin: 0 auto;

  text-align: center;
}